import React, {
  useCallback,
  useLayoutEffect,
  useMemo,
  useRef,
  useState,
} from "react";
import { useTranslation } from "react-i18next";

import { IS_APP, IS_DEV, IS_NODE } from "@/__main__/constants.mjs";
import router from "@/__main__/router.mjs";
import { appURLs } from "@/app/constants.mjs";
import adsRefs from "@/feature-ads/refs.mjs";
import {
  AdColumn,
  Container,
  DevDisplayAdContainer,
  DisplayAdBackdrop,
  DisplayAdBase,
  DisplayAdLeaderboard,
  DisplayAdRectangle,
  // DisplayAdTall,
  InnerContainer,
  StickyWrapper,
} from "@/feature-ads-baseline/AdWrapper.style.jsx";
import {
  AD_VIEW_THRESHOLD,
  DISPLAY_AD_CLASS,
  SLOT_UNFILLED_CLASS,
} from "@/feature-ads-baseline/constants.mjs";
import CloseIcon from "@/inline-assets/close-icon.svg";
import {
  appRoutes as marketingAppRoutes,
  homepageRoutes,
} from "@/routes/marketing.mjs";
import { classNames } from "@/util/class-names.mjs";
import globals from "@/util/global-whitelist.mjs";
import { useRouteComponent } from "@/util/router-hooks.mjs";
import useCallbackRef from "@/util/use-callback-ref.mjs";
import { useSnapshot } from "@/util/use-snapshot.mjs";

export function DisplayAd({
  id,
  placeholder = null,
  viewable = false,
  wrapper: AdContainer = IS_DEV ? DevPercentDisplayAd : "div",
  children = null,
  className = "",
  containerClassName = "",
  style = null,
}) {
  const ref = useRef(null);

  // Initialize as unfilled
  useLayoutEffect(() => {
    if (!ref.current) return;
    ref.current.parentElement.classList.add(SLOT_UNFILLED_CLASS);
  }, []);

  return (
    <div {...classNames(containerClassName)}>
      <AdContainer {...classNames(DisplayAdBase(), className)}>
        {placeholder && <div className="placeholder">{placeholder}</div>}
        <div
          ref={ref}
          id={id}
          style={style}
          {...classNames(DISPLAY_AD_CLASS, viewable && "viewable")}
        />
        {children}
      </AdContainer>
    </div>
  );
}

function shouldShowAds() {
  const { route } = router;
  if (!route) return false;
  const acceptableWidth = IS_NODE || globals.innerWidth >= 1366;
  return (
    acceptableWidth &&
    route.currentPath !== "/" &&
    !route.currentPath.startsWith("/getting-started") &&
    !route.currentPath.includes("/patchnotes") &&
    !route.currentPath.includes("/season-review") &&
    !route.currentPath.startsWith("/tft/set-") &&
    !route.currentPath.startsWith("/lol/champions") &&
    !route.currentPath.startsWith("/palworld/database") &&
    !route.currentPath.startsWith("/tft/traits") &&
    !marketingAppRoutes.find((r) => r.component === route.component) &&
    !homepageRoutes.find((r) => r.component === route.component)
  );
}

function useDisplayAnchorAd() {
  const { remoteConfig } = useSnapshot(adsRefs);

  const canShowAnchorAd = useMemo(() => {
    return remoteConfig.adhesion;
  }, [remoteConfig.adhesion]);

  return canShowAnchorAd;
}

function useAdsLayout() {
  const _rc = useRouteComponent(); // this is just used to trigger a re-render
  return shouldShowAds();
}

function AdWrapper({ children }) {
  useLayoutEffect(() => {
    const viewabilityObs = new IntersectionObserver(
      (entries) => {
        for (const entry of entries) {
          const method = entry.isIntersecting ? "add" : "remove";
          entry.target.classList[method]("viewable");
        }
      },
      { threshold: AD_VIEW_THRESHOLD },
    );

    return () => {
      viewabilityObs.disconnect();
    };
  }, []);

  const { t } = useTranslation();
  const isAdsLayout = useAdsLayout();
  const displayAnchorAd = useDisplayAnchorAd();

  return (
    <Container>
      <InnerContainer className="inner-wrapper-col">
        {isAdsLayout ? (
          <StickyWrapper>
            <AdColumn className="left-col">
              <div
                className={DisplayAdBackdrop()}
                data-label={"Advertisements"}
              >
                <a href={"/pro"} className="ad-removal-promo">
                  {t("common:removeAds", "Remove Ads")}
                </a>
              </div>
              {/* <DisplayAd
                placeholder={<AdPlaceholder prefix="tall" />}
                id={"display-lr-1"}
                style={{ inset: "0 0 auto auto" }}
                className={DisplayAdTall()}
              /> */}
              <DisplayAd
                placeholder={<AdPlaceholder />}
                id={"BLTZGG_DESKTOP_ROS_LR2"}
                style={{ inset: "0 0 auto auto" }}
                className={DisplayAdRectangle()}
              />
              <DisplayAd
                placeholder={<AdPlaceholder />}
                id={"BLTZGG_DESKTOP_ROS_LR3"}
                style={{ inset: "0 0 auto auto" }}
                className={DisplayAdRectangle()}
              />
            </AdColumn>
            <AdColumn>
              {/* Label must be "Advertisements" or "Sponsored Links". No other variations are allowed at this time.
          See https://support.google.com/adsense/answer/4533986?hl=en */}
              <div
                className={DisplayAdBackdrop()}
                data-label={"Advertisements"}
              >
                <a href={"/premium"} className="ad-removal-promo">
                  {t("common:removeAds", "Remove Ads")}
                </a>
              </div>
              <DisplayAd
                placeholder={<AdPlaceholder />}
                id={"display-rr-1"}
                className={DisplayAdRectangle()}
              />
              <DisplayAd
                placeholder={<AdPlaceholder />}
                id={"display-rr-2"}
                className={DisplayAdRectangle()}
              />
            </AdColumn>
          </StickyWrapper>
        ) : null}
        {children}
      </InnerContainer>
      {isAdsLayout && displayAnchorAd ? <AdhesionBannerContainer /> : null}
    </Container>
  );
}

function AdhesionBannerContainer() {
  const [visible, setVisible] = useState(true);

  const onUserClose: React.MouseEventHandler<HTMLButtonElement> = useCallback(
    (e) => {
      e.preventDefault();
      setVisible(false);
    },
    [],
  );

  if (!visible) return null;

  return (
    <DisplayAd
      id={"display-desktop-anchor"}
      style={{ maxHeight: "90px" }}
      className={DisplayAdLeaderboard()}
    >
      <button className="close-btn" onClick={onUserClose}>
        <CloseIcon />
      </button>
    </DisplayAd>
  );
}

function DevPercentDisplayAd({ children, ...props }) {
  const ref = useCallbackRef((node: HTMLDivElement) => {
    const obs = new IntersectionObserver(
      ([entry]) => {
        entry.target.childNodes.forEach((el) => {
          if (!(el instanceof HTMLElement)) return;
          el.innerHTML = (entry.intersectionRatio * 100).toFixed(3) + "%";
          const UPPER = 0.56;
          const LOWER = 0.51;
          const range = UPPER - LOWER;
          const percent = (entry.intersectionRatio - LOWER) / range;
          el.style.color =
            entry.intersectionRatio < UPPER
              ? entry.intersectionRatio > LOWER
                ? `rgb(${(1 - percent) * 255}, 255, 0)`
                : "red"
              : "";
        });
      },
      { threshold: Array.from({ length: 1000 }, (_, i) => i / 1000) },
    );
    obs.observe(node);

    return () => obs.disconnect();
  });

  return (
    <div
      {...props}
      {...classNames(props.className, DisplayAdBase(), DevDisplayAdContainer())}
    >
      {children}
      <div ref={ref} className="dev percents">
        <div />
        <div />
        <div />
        <div />
      </div>
      <div className="dev center-hints" />
    </div>
  );
}

export default AdWrapper;

const maxIndex = 6;
const prefixes = ["tall", "slot"];
export const promoImgMap = {};
for (const prefix of prefixes) {
  promoImgMap[prefix] = new Array(maxIndex).fill(null).map((_, i) => {
    return `${appURLs.CDN}/self-promotion/premium/${prefix}_${i + 1}.webp`;
  });
}

const availableIndices = new Set(
  new Array(maxIndex).fill(null).map((_, i) => i + 1),
);
const unavailableIndices = new Set();

type AdPlaceholderProps = {
  prefix?: "tall" | "slot";
  img?: string;
  link?: string;
};

export function AdPlaceholder({
  prefix = "slot",
  img,
  link,
}: AdPlaceholderProps) {
  const { t } = useTranslation();
  const [index, setIndex] = useState(null);

  useLayoutEffect(() => {
    let i;
    while (availableIndices.size && !availableIndices.has(i)) {
      i = ~~(Math.random() * maxIndex);
    }
    setIndex(i);
    availableIndices.delete(i);
    unavailableIndices.add(i);
    return () => {
      availableIndices.add(i);
      unavailableIndices.delete(i);
    };
  }, []);

  if (!index) return null;

  const imgUrl = img ? img : promoImgMap[prefix][index];
  const href = link
    ? link
    : `/pro?utm_source=blitz&utm_medium=ad&utm_campaign=blitz_ad_${index}`;
  const alt = t("common:getSubscriber", "Get Premium");

  return (
    <a href={href} {...{ target: IS_APP ? undefined : "_blank" }}>
      <img src={imgUrl} alt={alt} />
    </a>
  );
}
