import { css, styled } from "goober";
import { mobile } from "clutch/src/Style/style.mjs";

import {
  AD_HEIGHT,
  AD_HEIGHT_DOUBLE,
  AD_MIN_WIDTH,
  AD_WIDTH,
  DISPLAY_AD_CLASS,
  SLOT_UNFILLED_CLASS,
} from "@/feature-ads-baseline/constants.mjs";

/// `FULL_HEIGHT_ADS`: ads should be prioritized in layout.
/// `true`: sidebar ads may be displayed out of vertical alignment with page
/// content to allow more ad units to fit. max amount of ads is determined
/// by screen height
/// `false`: sidebar ads will always align with page content. max amount of ads
/// is determined by the initial height available to the ad column
/// NOTE: we are setting this in the style file so it can be used const by the style constructors
export const FULL_HEIGHT_ADS = false;

export const InnerContainer = styled("div")`
  flex: var(--is-full-width) 1 var(--sp-container);
  width: 100%;
  align-self: stretch;
  min-height: 100%;
  /*max-width: var(--page-width, --sp-container);*/
  position: relative;
  background: var(--app-bg);
`;

export const Container = styled("div")`
  --ad-padding: var(--sp-2);
  /* needed for ad rail sticky */
  display: flex;
  align-items: flex-start;
  justify-content: center;
  /*gap: calc(var(--page-padding) + var(--ad-padding));*/
  flex: 1 1 auto;
  width: 100%;
  position: relative;

  > * {
    min-inline-size: 0;
  }
`;

export const StickyWrapper = styled("div")`
  position: sticky;
  top: var(--sp-4);
  left: calc(50% - (var(--page-width, --sp-container) - var(--sp-2)) / 2);
  z-index: 10;
  max-width: var(--page-width, --sp-container);
`;

export const AdColumn = styled("div")`
  --gutter: var(--sp-6);
  --label-height: var(--sp-4);
  position: absolute;
  top: 0;
  left: calc(100% + var(--gutter));
  padding-block-start: calc(var(--label-height) + var(--ad-padding));
  padding-block-end: var(--ad-padding);
  &.left-col {
    left: auto;
    right: calc(100% + var(--gutter));
  }
  & > *:not(:last-child) {
    margin-block-end: var(--sp-2);
  }
`;

export const DisplayAdBase = () => css`
  position: relative;

  color: var(--shade3);
  background: var(--shade8);
  border-radius: var(--br-lg);
  overflow: hidden;

  > * {
    position: absolute;
    top: 0;
    /* do not add left: 0 here; it's not necessary for layout and breaks left
       rail ads, which need to hug the right edge */
  }

  .${DISPLAY_AD_CLASS} {
    max-width: 100%;
    overflow: hidden;
  }

  .${DISPLAY_AD_CLASS}:not(:has(iframe)) {
    pointer-events: none;
  }

  .placeholder {
    display: none;
    justify-content: center;
    align-items: center;
    width: 100%;
    height: 100%;
    color: var(--shade6);
    overflow: hidden;

    img {
      display: block;
      width: 100%;
      height: 100%;
    }
  }

  &:has(iframe) .placeholder,
  &:has(aditude-video-ad) .placeholder {
    display: none !important;
  }

  /* subtle indicator that ad is filling or not */
  outline: 1px solid var(--shade6-50);
  &.${SLOT_UNFILLED_CLASS} {
    outline: none;
    .placeholder {
      display: flex;
    }
  }
`;

// TODO: maybe support larger sizes later
// TODO: since css factory refactor we should change these names to clarify how to compose them
export const DisplayAdLeaderboard = () => css`
  --width: 728px;
  height: 90px;
  width: var(--width);
  background: transparent;
  outline: none;
  position: fixed;
  bottom: 0;
  /* prevent overlap with right rail ads */
  right: max(${AD_MIN_WIDTH}px + var(--sp-4), calc(50vw - var(--width) / 2));
  z-index: 14;

  &.top-banner {
    position: relative;
    bottom: unset;
    right: unset;
    z-index: unset;
  }

  ${mobile} {
    display: none;
  }

  &:has(iframe) {
    background: var(--shade6);
  }

  .close-btn {
    padding: 0;
    --btn-size: var(--sp-4);
    background: var(--shade10-75);
    position: absolute;
    border-radius: 50%;
    top: calc(-1 * var(--btn-size) - var(--sp-1));
    left: calc(var(--width) - var(--btn-size));
    width: var(--btn-size);
    height: var(--btn-size);
    color: var(--shade0-75);
    cursor: pointer;
    border: 1px solid var(--shade0-50);
  }
  &:not(:has(iframe)) .close-btn {
    display: none;
  }
`;

export const DisplayAdRectangle = () => css`
  height: ${AD_HEIGHT}px;
  width: ${AD_WIDTH}px;
`;

export const DisplayAdTall = () => css`
  height: ${AD_HEIGHT_DOUBLE}px;
  width: ${AD_WIDTH}px;
`;

export const DisplayAdBackdrop = () => css`
  position: absolute;
  top: 0;
  left: calc(-1 * var(--ad-padding));
  right: calc(-1 * var(--ad-padding));
  min-width: calc(${AD_WIDTH}px + 2 * var(--ad-padding));
  height: 100%;
  border-radius: var(--br-lg);
  background: var(--shade6);
  border: 1px solid var(--shade0-15);
  &:before {
    display: block;
    content: attr(data-label);

    margin-inline: var(--ad-padding);
    margin-block-start: var(--sp-1);

    color: var(--shade0-50);
    font-size: var(--sp-2_5);
    font-weight: 900;
    text-transform: uppercase;
    letter-spacing: 0.01rem;
    text-align: left;
  }

  .ad-removal-promo {
    display: flex;
    position: absolute;
    top: var(--sp-1);
    right: 0;
    margin-right: var(--sp-1_5);

    align-items: center;

    font-size: var(--sp-2_5);
    font-weight: 900;
    text-transform: uppercase;
    letter-spacing: 0.01rem;

    color: var(--subscriber);

    &:hover {
      color: var(--shade0);
    }
  }
`;

export const DisplayAdCentered = () => css`
  display: flex;
  width: 728px;
  flex-direction: column;
  margin: auto;
  padding-bottom: var(--sp-4);
`;

export const DevDisplayAdContainer = () => css`
  .dev {
    pointer-events: none;
  }
  .percents {
    position: absolute;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    display: grid;
    grid-template-columns: auto auto;
    justify-content: space-between;
    align-content: space-between;
    > * {
      background-color: var(--shade10-75);
      padding: var(--sp-1);
      margin: var(--sp-1);
      color: var(--shade0);
    }
  }
  .center-hints {
    position: absolute;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    &::before {
      content: "";
      position: absolute;
      top: 0;
      bottom: 0;
      width: 1px;
      background-color: var(--shade5-50);
      position: absolute;

      left: 50%;
      transform: translateX(-50%);
    }
  }
`;
